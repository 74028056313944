import { Menu, Transition } from '@headlessui/react';
import {
    Cog6ToothIcon,
    CogIcon,
    HomeIcon,
    SparklesIcon,
    TableCellsIcon,
} from '@heroicons/react/24/outline';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Fragment } from 'react';

import { signOutUser } from '../services/authService';
import { profilePictureUrl, user } from '../state/user';
import { Link } from './CustomRouter';
import KnockWidget from './KnockFeedWidget';

// These types probably need to live somewhere else but this is fine for now
// Got them from stack: https://stackoverflow.com/a/76042282/2446724
type IconSVGProps = React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> &
    React.RefAttributes<SVGSVGElement>;
type IconProps = IconSVGProps & {
    title?: string;
    titleId?: string;
};

interface NavItem {
    name: string;
    href: string;
    icon: React.FC<IconProps>;
}

function Nav() {
    const navigations: NavItem[] = (() => {
        const flags = {
            pillar: { name: 'Pillar', href: '/pillar', icon: HomeIcon },
            lexi: { name: 'AI Audit', href: '/lexi', icon: SparklesIcon },
            worksheets: { name: 'Worksheets', href: '/', icon: TableCellsIcon },
            settings: { name: 'Settings', href: '/settings', icon: Cog6ToothIcon },
            utilities: { name: 'Utilities', href: '/utilities', icon: CogIcon },
        };
        const result: NavItem[] = [];
        Object.entries(flags).map(([k, v]) => {
            if (useFeatureFlagEnabled(`${k}-feature`)) result.push(v as NavItem);
        });
        return result;
    })();

    const userNavigation = [{ name: 'Sign out', href: '#', action: handleSignOut }];

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ');
    }

    async function handleSignOut(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault();
        signOutUser();
    }

    return (
        <div className="fixed inset-y-0 rounded-lg m-4 z-10 block w-20 bg-white shadow pb-4">
            <Menu
                as="div"
                className="relative mt-4 h-16 flex items-center justify-center"
            >
                <div>
                    <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <img
                            className="h-8 w-8 rounded-full"
                            src={profilePictureUrl.value}
                            alt={`${user.value.firstName ? user.value.firstName[0] : '?'} ${user.value.lastName ? user.value.lastName[0] : '?'}`}
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute left-0 top-10 z-10 mt-4 w-48 origin-top-left rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                                {({ active }) => (
                                    <a
                                        href={item.href}
                                        onClick={handleSignOut}
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700',
                                        )}
                                    >
                                        {item.name}
                                    </a>
                                )}
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </Transition>
            </Menu>

            <nav className="mt-4">
                <ul className="flex flex-col items-center space-y-1">
                    {navigations.map((item) => (
                        <li key={item.name}>
                            <Link
                                to={item.href}
                                className="[&.active]:bg-gray-50 [&.active]:text-indigo-600 group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold text-gray-700 hover:text-indigo-600 hover:bg-gray-50"
                            >
                                <item.icon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                />
                                <span className="sr-only">{item.name}</span>
                            </Link>
                        </li>
                    ))}
                    {useFeatureFlagEnabled(`settings-feature`) && (
                        <KnockWidget></KnockWidget>
                    )}
                </ul>
            </nav>
        </div>
    );
}

export default Nav;
