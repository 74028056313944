import { DocumentIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { CustomCellRendererProps } from 'ag-grid-react';
import React from 'react';

import { Attachment } from '../Types/Card';

export const FILE_ICONS: Record<string, React.ReactNode> = {
    image: <PhotoIcon></PhotoIcon>,
    document: <DocumentIcon></DocumentIcon>,
};

interface GridCellAttachmentsParams extends CustomCellRendererProps {
    prop: string;
}

function GridCellAttachments({ data: row, prop }: GridCellAttachmentsParams) {
    const attachments = row[prop] as Attachment[];

    const icon = (attachmentType: string) =>
        FILE_ICONS[attachmentType] || FILE_ICONS['document'];
    return (
        <div className="flex flex-col h-100 gap-2">
            {(attachments || []).map((attachment) => {
                return (
                    <div key={attachment.id} className="p-1 border-b">
                        <a
                            className="flex flex-row gap-2 content-center items-center cursor-pointer"
                            target="_blank"
                            href={attachment.url}
                            rel="noreferrer"
                        >
                            <span className="h-6 w-6">{icon(attachment.type)}</span>
                            <span className="w-3/4">{attachment.name}</span>
                        </a>
                    </div>
                );
            })}
        </div>
    );
}

export default GridCellAttachments;
