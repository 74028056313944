import { CustomCellRendererProps } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';

import { useModal } from '../providers/Modal';
import {
    CreateReviewPayload,
    UpdateReviewPayload,
    useCreateMessage,
    useCreateReview,
    useGetMessages,
    useGetReview,
    useUpdateReview,
} from '../services/reviewService';
import { selectedAudit } from '../state/audit';
import {
    ApiReview,
    REVIEW_STATUS,
    ReviewMessage,
    ReviewStatus,
    ReviewType,
} from '../Types/Review';
import { Messaging } from './Messaging';
import ReviewStatusSelect from './ReviewStatusSelect';
interface GridCellReviewProps {
    type: ReviewType;
}

interface GridCellReviewParams extends CustomCellRendererProps {
    prop: GridCellReviewProps;
    auditId: number;
}

interface ModalContentProps {
    onSubmit: (message: Partial<ReviewMessage>) => void;
    onCancel: () => void;
    reviewId: number;
}

const ModalContent: React.FC<ModalContentProps> = ({ reviewId, onSubmit }) => {
    const { data: messages, isError, error, isLoading } = useGetMessages(reviewId);
    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error: {error.message}</div>;

    return (
        <div className="flex flex-col bg-white">
            <div className="sm:flex sm:items-start ">
                <div className="grow mt-3 text-center sm:text-left">
                    <Messaging
                        messages={messages || []}
                        onNewMessage={onSubmit}
                    ></Messaging>
                </div>
            </div>
        </div>
    );
};

function GridCellReview({ data: row, prop }: GridCellReviewParams) {
    const [reviewId, setReviewId] = useState(row?.[prop.type]?.id);

    useEffect(() => {
        setReviewId(row?.[prop.type]?.id);
    }, [row, prop]);

    const reviewType = prop.type;
    const auditId = selectedAudit?.value?.id;
    const { data: review, isError, error, isLoading } = useGetReview(auditId, reviewId);
    const { showModal, hideModal } = useModal();
    const {
        mutate: createMutate,
        isSuccess: _createSuccess,
        status: _createStatus,
        isError: _createError,
    } = useCreateReview();
    const {
        mutate: updateMutate,
        isSuccess: _updateeSuccess,
        status: _updateStatus,
        isError: _updateError,
    } = useUpdateReview();
    const {
        mutate: createMessageMutate,
        isSuccess: _createMessageSuccess,
        status: _createMessageStatus,
        isError: _createMessageError,
    } = useCreateMessage(reviewId);
    const lastMessage = review?.messages[review.messages.length - 1] || undefined;
    const handleMessageSubmit = (newMessage: Partial<ReviewMessage>) => {
        if (!review) {
            const payload: CreateReviewPayload = {
                reviewableId: row.id,
                status: REVIEW_STATUS.responseRequired,
                type: reviewType,
                message: newMessage.message,
            };
            createMutate(payload, {
                onSuccess: (data: ApiReview, _variables, _variablescontext) => {
                    setReviewId(data.id);
                },
            });
        } else {
            createMessageMutate({ reviewId, message: newMessage.message || '' });
        }
    };

    const handleModalCancel = () => {
        hideModal();
    };

    const handleStatusUpdate = (status: ReviewStatus) => {
        if (!review) {
            const payload: CreateReviewPayload = {
                reviewableId: row.id,
                type: reviewType,
                status: status,
            };
            createMutate(payload, {
                onSuccess: (data: ApiReview, _variables, _variablescontext) => {
                    setReviewId(data.id);
                },
            });
        } else {
            const payload: UpdateReviewPayload = {
                reviewableId: row.id,
                reviewId: review.id,
                type: reviewType,
                status: status,
            };
            updateMutate(payload);
        }
    };

    if (isError) return <div>Error {error.message}</div>;
    if (isLoading) return <div>Loading</div>;

    return (
        <div className="flex flex-col h-100">
            <ReviewStatusSelect
                selected={review?.status || REVIEW_STATUS.notStarted}
                setSelected={handleStatusUpdate}
            ></ReviewStatusSelect>
            <div className="flex flex-col mt-4">
                {lastMessage?.message}
                <span className="pt-1 font-medium">{lastMessage?.createdBy.email}</span>
            </div>

            <div className="flex flex-col flex-grow align-bottom items-start text-xs font-medium">
                <button
                    onClick={() =>
                        showModal(
                            <ModalContent
                                onSubmit={handleMessageSubmit}
                                onCancel={handleModalCancel}
                                reviewId={reviewId}
                            />,
                            'Control Review',
                        )
                    }
                    className={`px-2 py-1 mt-4 bg-gray-50 text-gray-700 border border-gray-400 rounded flex gap-3`}
                >
                    {review?.messages.length || 0} comments
                </button>
            </div>
        </div>
    );
}

export default GridCellReview;
