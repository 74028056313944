import { CustomCellRendererProps } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';

import { useModal } from '../providers/Modal';
import { selectedAudit } from '../state/audit';

interface ModalContentProps {
    cardId: number;
    auditId: number;
    url: string;
    onCancel: () => void;
}

const ModalContent: React.FC<ModalContentProps> = ({
    cardId,
    auditId,
    url,
}: ModalContentProps) => {
    const origin = new URL(url).origin;
    const cardUrl = `${origin}/audits/${auditId}/card/${cardId}`;
    return (
        <>
            <div className="h-[calc(90vh-2rem)]">
                <iframe
                    src={cardUrl}
                    title="Card"
                    style={{ width: '100%', height: '100%' }}
                />
            </div>
        </>
    );
};

async function getPillarURL() {
    try {
        const response = await fetch('/pillar_embed');
        if (response.ok) {
            const url = await response.text();
            return url;
        } else {
            console.error('Failed to fetch the Pillar URL:', response.statusText);
        }
    } catch (error) {
        console.error('Error fetching the Pillar URL:', error);
    }
    return '';
}

function GridCellIframeContainer({ data: row }: CustomCellRendererProps) {
    const cardId = row.id;
    const auditId = selectedAudit?.value?.id || 0;
    const { showModal, hideModal } = useModal();
    const [pillarUrl, setPillarUrl] = useState('');

    const handleModalCancel = () => {
        hideModal();
    };

    useEffect(() => {
        async function fetchData() {
            const url = await getPillarURL();
            setPillarUrl(url);
        }
        fetchData();
    });

    return (
        <div className="flex flex-col">
            <div className="flex flex-col flex-grow align-bottom items-start text-xs font-medium">
                <button
                    onClick={() =>
                        showModal(
                            <ModalContent
                                onCancel={handleModalCancel}
                                cardId={cardId}
                                auditId={auditId}
                                url={pillarUrl}
                            />,
                            '',
                            'w-3/4',
                        )
                    }
                    className={`px-2 py-1 mt-4 bg-gray-50 text-gray-700 border border-gray-400 rounded flex gap-3`}
                >
                    {row.refId}
                </button>
            </div>
        </div>
    );
}

export default GridCellIframeContainer;
