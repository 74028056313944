import React from 'react';
import * as XLSX from 'xlsx';

import { useGetCards } from '../services/auditService';
import { titleToKeyMapping } from '../services/standardMappingTransformer';
import { TransformedAuditBoard } from '../Types/Audit';
import { Card } from '../Types/Card';
import { StandardMapping } from '../Types/Standards';

interface ExportXLSXProps {
    selectedAudit?: TransformedAuditBoard;
    filename: string;
}

interface ColumnDefinition {
    headerName: string;
    field: string;
}

const keyToTitleMapping: Record<keyof StandardMapping, string> = Object.keys(
    titleToKeyMapping,
).reduce(
    (acc, title) => {
        const key = titleToKeyMapping[title as keyof typeof titleToKeyMapping];
        acc[key] = title;
        return acc;
    },
    {} as Record<keyof StandardMapping, string>,
);

const generateStandardsColumnDefinitions = (auditTypes: string[]): ColumnDefinition[] => {
    const keys = (Object.keys(keyToTitleMapping) as Array<keyof StandardMapping>).filter(
        (key) => auditTypes.includes(keyToTitleMapping[key]),
    );

    const soc2Regex = /^soc2(?!all)/i;
    const soc2Fields = keys.filter((key) => soc2Regex.test(key));
    const otherFields = keys.filter((key) => !soc2Regex.test(key));

    const columns: ColumnDefinition[] = otherFields.map((key) => ({
        headerName: keyToTitleMapping[key],
        field: key,
    }));

    if (soc2Fields.length > 0) {
        columns.push({
            headerName: 'SOC 2',
            field: 'soc2',
        });
    }

    return columns;
};

const generateStandardsRowData = (
    standards: ColumnDefinition[],
    values: StandardMapping,
): XLSX.CellObject[] => {
    return standards.map((standard) => {
        if (standard.field === 'soc2') {
            const soc2Keys = Object.keys(values).filter((key) =>
                /^soc2(?!all)/i.test(key),
            ) as (keyof StandardMapping)[];
            const combinedSOC2Values = soc2Keys
                .map((key) => values[key])
                .flat()
                .filter(Boolean)
                .join(', ');
            return {
                v: combinedSOC2Values || '',
                t: 's',
            } as XLSX.CellObject;
        }

        return {
            v: (values[standard.field as keyof StandardMapping] || []).flat().join(','),
            t: 's',
        } as XLSX.CellObject;
    });
};

const ExportXLSX: React.FC<ExportXLSXProps> = ({ selectedAudit, filename }) => {
    const { data: cards, error, isLoading } = useGetCards(selectedAudit?.id);

    if (error || isLoading) return '';

    const generateXLSX = () => {
        const workbook = XLSX.utils.book_new();
        const standards = generateStandardsColumnDefinitions(
            selectedAudit?.auditType || [],
        );

        // Generate headers dynamically
        const headers: XLSX.CellObject[] = [
            { v: 'Section', t: 's' },
            { v: 'Control Description', t: 's' },
            { v: 'Service Auditor Tests', t: 's' },
            { v: 'Results', t: 's' },
            { v: 'Test Results', t: 's' },
            { v: 'Ref ID', t: 's' },
            { v: 'Frequency', t: 's' },
            { v: 'Importance', t: 's' },
            { v: 'Testing Status', t: 's' },
            ...standards.map((standard) => ({
                v: standard.headerName,
                t: 's' as XLSX.ExcelDataType,
            })),
        ];

        // Add headers to worksheet data
        const ws_data: XLSX.CellObject[][] = [headers];

        // Add row data
        cards?.forEach((pCard: Partial<Card>) => {
            const card = pCard as Card;

            const addRow = (row: XLSX.CellObject[]) => {
                ws_data.push(row);
            };

            const baseRow: XLSX.CellObject[] = [
                { v: card.section, t: 's' },
                {
                    v: card.controlDescription,
                    t: 's',
                },
                {
                    v: card.serviceAuditorTests,
                    t: 's',
                },
                { v: card.results.status, t: 's' },
                {
                    v: card.serviceAuditorTestResults,
                    t: 's',
                },
                { v: card.refId, t: 's' },
                { v: card.frequency || '', t: 's' },
                { v: card.importance, t: 's' },
                { v: card.status, t: 's' },
                ...generateStandardsRowData(standards, card.standardMapping),
            ];

            if (card.results.details) {
                const { inquiry, exceptionDisclosure, basisOfDesign } =
                    card.results.details;

                if (inquiry) {
                    const inquiryRow = [...baseRow];
                    inquiryRow[2] = { v: inquiry.tests || '', t: 's' }; // service auditor tests
                    inquiryRow[3] = { v: 'No Exceptions Noted' || '', t: 's' }; // test results
                    inquiryRow[4] = { v: inquiry.results || '', t: 's' }; // test results
                    inquiryRow[5] = { v: 'N/A - Inquiry', t: 's' }; // Ref ID
                    addRow(inquiryRow);
                }

                if (exceptionDisclosure) {
                    baseRow[0] = { v: '', t: 's' }; // remove section
                    baseRow[1] = { v: '', t: 's' }; // remove control description
                    baseRow[2] = { v: card.serviceAuditorTests || '', t: 's' }; // service auditor tests
                    baseRow[3] = { v: exceptionDisclosure.notes || '', t: 's' }; // results
                    baseRow[4] = { v: exceptionDisclosure.results || '', t: 's' }; // test results
                }

                addRow(baseRow);

                if (basisOfDesign) {
                    const basisOfDesignRow = [...baseRow];
                    basisOfDesignRow[0] = { v: '', t: 's' }; // remove section
                    basisOfDesignRow[1] = { v: '', t: 's' }; // remove control description
                    basisOfDesignRow[2] = { v: basisOfDesign.tests || '', t: 's' }; // service auditor tests
                    basisOfDesignRow[3] = { v: 'No Exceptions Noted' || '', t: 's' }; // test results
                    basisOfDesignRow[4] = { v: basisOfDesign.results || '', t: 's' }; // test results
                    basisOfDesignRow[5] = { v: 'N/A - Basis of Design', t: 's' }; // Ref ID
                    addRow(basisOfDesignRow);
                }
            } else {
                addRow(baseRow);
            }
        });

        const worksheet = XLSX.utils.aoa_to_sheet(ws_data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Dash Export');
        XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    return (
        <button
            onClick={generateXLSX}
            className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
            Export to XLSX
        </button>
    );
};

export default ExportXLSX;
