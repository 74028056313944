import { StandardMapping } from '../Types/Standards';

export const titleToKeyMapping: Record<string, keyof StandardMapping> = {
    'SOC 2 - Security': 'soc2Security',
    'SOC 2 - Availability': 'soc2Availability',
    'SOC 2 - Confidentiality': 'soc2Confidentiality',
    'SOC 2 - Processing Integrity': 'soc2ProcessingIntegrity',
    'SOC 2 - Privacy': 'soc2Privacy',
    'NIST CSF': 'nistCsf',
    'COBIT 5': 'cobit5',
    'CIS CSC': 'cisCsc',
    'PCI-DSS 4.0': 'pciDss',
    MVSP: 'mvsp',
    HIPAA: 'hipaa',
    CCPA: 'ccpa',
    GDPR: 'gdpr',
    'AU PA': 'auPa',
    'NZ PA': 'nzPa',
    CDR: 'cdr',
    'ISO 27001:2013': 'iso27001',
    'ISO 27001 ISMS': 'iso27001Isms',
    'ISO 27001:2022': 'iso27001_2022',
    'ISO 27017': 'iso27017',
    'ISO 27701': 'iso27701',
    'CSA STAR': 'csaStar',
    'CPS 234': 'cps234',
    'Essential Eight': 'essentialEight',
    'Modern Slavery': 'modernSlavery',
    ESG: 'esg',
    GRI: 'gri',
    'UN SDGs': 'unSdg',
    CBA: 'cba',
    'Investment Management': 'investmentManagement',
    Custody: 'custody',
    'Fund Administration': 'fundAdministration',
    Registry: 'registry',
    'PwC Singapore': 'pwcSingapore',
};

export function transformStandardMappings(
    standards: Record<string, string>,
): StandardMapping {
    const mapping: StandardMapping = {
        all: [],
        soc2Security: [],
        soc2Availability: [],
        soc2Confidentiality: [],
        soc2ProcessingIntegrity: [],
        soc2Privacy: [],
        nistCsf: [],
        cobit5: [],
        cisCsc: [],
        pciDss: [],
        mvsp: [],
        hipaa: [],
        ccpa: [],
        gdpr: [],
        auPa: [],
        nzPa: [],
        cdr: [],
        iso27001: [],
        iso27001Isms: [],
        iso27001_2022: [],
        iso27017: [],
        iso27701: [],
        csaStar: [],
        cps234: [],
        essentialEight: [],
        modernSlavery: [],
        esg: [],
        gri: [],
        unSdg: [],
        cba: [],
        investmentManagement: [],
        custody: [],
        fundAdministration: [],
        registry: [],
        pwcSingapore: [],
    };

    Object.entries(standards).forEach(([key, value]) => {
        const mappedKey = titleToKeyMapping[key];
        if (mappedKey) {
            const values = (value ?? '').split(',').map((v) => v.trim());
            mapping[mappedKey].push(...values);
        }
    });

    return mapping;
}

export function transformStandardMappingsToApi(
    mapping: StandardMapping,
): Record<string, string> {
    const standards: Record<string, string> = {};

    Object.entries(mapping).forEach(([mappedKey, values]) => {
        if (mappedKey === 'all') return; // Skip the 'all' key as it is a collection of all other values.

        const title = Object.keys(titleToKeyMapping).find(
            (key) => titleToKeyMapping[key] === mappedKey,
        );

        if (title) {
            standards[title] = (values ?? []).join(', ');
        }
    });
    return standards;
}
