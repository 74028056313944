//GridResults.tsx
import { CheckIcon } from '@heroicons/react/24/outline';
import { CustomCellRendererProps } from 'ag-grid-react';
import React, { useState } from 'react';

import { useModal } from '../providers/Modal';
import { useGetCard, useUpdateCard } from '../services/auditService';
import { selectedAudit } from '../state/audit';
import { Card, ResultDetails, TestResult } from '../Types/Card';
import ResultDetailsComponent from './ResultDetails';
import { SelectBox } from './ui/Select';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export enum RESULT_STATUS_STRINGS {
    NO_EXCEPTIONS = 'No Exceptions Noted',
    EXCEPTIONS = 'Exceptions Noted',
    POSITIVE_OBSERVATION = 'Positive Observation',
    PROCESS_IMPROVEMENT = 'Process Improvement',
    MINOR_NON_CONFORMITY = 'Minor Non-conformity',
    MAJOR_NON_CONFORMITY = 'Major Non-conformity',
}

const resultStatusColorMapping = (value: string) => {
    switch (value) {
        case RESULT_STATUS_STRINGS.NO_EXCEPTIONS:
            return { backgroundColor: 'bg-green-500' };
        case RESULT_STATUS_STRINGS.EXCEPTIONS:
            return { backgroundColor: 'bg-red-500' };
        case RESULT_STATUS_STRINGS.POSITIVE_OBSERVATION:
            return { backgroundColor: 'bg-blue-500' };
        case RESULT_STATUS_STRINGS.PROCESS_IMPROVEMENT:
            return { backgroundColor: 'bg-gray-200' };
        case RESULT_STATUS_STRINGS.MINOR_NON_CONFORMITY:
        case RESULT_STATUS_STRINGS.MAJOR_NON_CONFORMITY:
            return { backgroundColor: 'bg-yellow-500' };
        default:
            return { backgroundColor: 'bg-gray-200' };
    }
};

interface GridCellReviewParams extends CustomCellRendererProps {
    prop: string;
}

interface ModalContentProps {
    onSubmit: (resultDetails: ResultDetails) => void;
    onCancel: () => void;
    resultDetails: ResultDetails;
}

const ModalContent: React.FC<ModalContentProps> = ({
    onSubmit,
    onCancel,
    resultDetails,
}) => {
    const [localState, setLocalState] = useState(resultDetails);
    return (
        <>
            <div className="flex bg-white py-4 ">
                <div className="sm:flex sm:items-start grow">
                    <div className="grow mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <ResultDetailsComponent
                            resultDetails={resultDetails}
                            setResultDetails={setLocalState}
                        ></ResultDetailsComponent>
                    </div>
                </div>
            </div>
            <div className=" sm:flex sm:flex-row-reverse ">
                <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                    onClick={() => onSubmit(localState)}
                >
                    Save
                </button>
                <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={onCancel}
                >
                    Cancel
                </button>
            </div>
        </>
    );
};

const ensureResultDetails = (
    details: ResultDetails | null | undefined,
): ResultDetails => {
    const defaultSection = { notes: '', tests: '', results: '' };
    return {
        inquiry: details?.inquiry || defaultSection,
        exceptionDisclosure: details?.exceptionDisclosure || defaultSection,
        basisOfDesign: details?.basisOfDesign || defaultSection,
    };
};

const resultHasInquiry = (details?: ResultDetails) => {
    if (!details || !details.inquiry) return false;
    return (
        !!details.inquiry?.notes || !!details.inquiry?.tests || !!details.inquiry?.results
    );
};

const resultHasException = (details?: ResultDetails) => {
    if (!details || !details.exceptionDisclosure) return false;
    return (
        !!details.exceptionDisclosure?.notes ||
        !!details.exceptionDisclosure?.tests ||
        !!details.exceptionDisclosure?.results
    );
};

const resultHasBasis = (details?: ResultDetails) => {
    if (!details || !details.basisOfDesign) return false;
    return (
        !!details.basisOfDesign.notes ||
        !!details.basisOfDesign.tests ||
        !!details.basisOfDesign.results
    );
};

const resultHasDetails = (details?: ResultDetails) => {
    if (!details) return false;
    return (
        resultHasInquiry(details) ||
        resultHasException(details) ||
        resultHasBasis(details)
    );
};

const shouldShowDetailsButton = (result?: TestResult) => {
    if (!result) return false;
    if (
        result.status != RESULT_STATUS_STRINGS.NO_EXCEPTIONS ||
        resultHasDetails(result.details)
    )
        return true;

    return false;
};

function GridCellResult({ data: row }: GridCellReviewParams) {
    const auditId = selectedAudit?.value?.id;
    const cardId = row?.id;
    const resultStatusValues = Object.values(RESULT_STATUS_STRINGS);

    const {
        data: card,
        isError: _e,
        error: _err,
        isLoading: _l,
    } = useGetCard(auditId, cardId);
    const updateCardMutation = useUpdateCard();

    const selected =
        card?.results?.status || RESULT_STATUS_STRINGS.NO_EXCEPTIONS.toString();
    const resultDetails =
        card?.results?.details || ensureResultDetails(card?.results?.details);

    const { showModal, hideModal } = useModal();

    const handleSubmit = (newResultDetails: ResultDetails) => {
        const updatedCard: Partial<Card> = {
            results: {
                status:
                    (card?.status as RESULT_STATUS_STRINGS) ||
                    RESULT_STATUS_STRINGS.NO_EXCEPTIONS,
                details: newResultDetails,
            },
        };

        if (card?.id) {
            updateCardMutation.mutate({
                cardId: card.id,
                card: updatedCard,
            });
        }
        hideModal();
    };

    const handleStatusChange = (status: string) => {
        const updatedCard: Partial<Card> = {};
        updatedCard.results = {
            status,
        };
        if (card?.id) {
            updateCardMutation.mutate({
                cardId: card.id,
                card: updatedCard,
            });
        }
    };

    const handleCancel = () => {
        hideModal();
    };

    const handleShowModal = () => {
        showModal(
            <ModalContent
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                resultDetails={resultDetails}
            />,
            'Result Details',
        );
    };

    return (
        <div className="flex flex-col h-100">
            <SelectBox
                onChange={handleStatusChange}
                items={resultStatusValues}
                selected={selected}
                getItemLabel={(status) => status}
                getItemIcon={(status) => (
                    <span
                        aria-label={status}
                        className={classNames(
                            resultStatusColorMapping(status)?.backgroundColor ?? '',
                            'inline-block h-2 w-2 flex-shrink-0 rounded-full',
                        )}
                    />
                )}
            ></SelectBox>
            {resultHasDetails(card?.results?.details) && (
                <div className="p-1.5 text-xs text-gray-800">
                    {resultHasInquiry(card?.results?.details) && (
                        <div className="flex flex-row h-4">
                            <CheckIcon className="pr-1.5"></CheckIcon>Inquiry
                        </div>
                    )}
                    {resultHasException(card?.results?.details) && (
                        <div className="flex flex-row h-4">
                            <CheckIcon className="pr-1.5"></CheckIcon>
                            Exception/Disclosure
                        </div>
                    )}
                    {resultHasBasis(card?.results?.details) && (
                        <div className="flex flex-row h-4">
                            <CheckIcon className="pr-1.5"></CheckIcon>Basis of Design
                        </div>
                    )}
                </div>
            )}
            {shouldShowDetailsButton(card?.results) && (
                <div className="flex flex-col flex-grow align-bottom items-start text-xs font-medium">
                    <button
                        onClick={handleShowModal}
                        className={`px-2 py-1 mt-4 bg-gray-50 text-gray-700 border border-gray-400 rounded flex gap-3`}
                    >
                        Details
                    </button>
                </div>
            )}
        </div>
    );
}

export default GridCellResult;
