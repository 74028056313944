import { useEffect, useState } from 'react';

import { user } from '../state/user';
import { ReviewMessage } from '../Types/Review';

interface MessagingProps {
    onNewMessage: (newMessage: Partial<ReviewMessage>) => void;
    messages: ReviewMessage[];
}

const groupMessagesByUser = (messages: ReviewMessage[]) => {
    const groups: { [key: string]: ReviewMessage[] } = {};
    messages.forEach((message) => {
        if (!groups[message.createdBy.email]) {
            groups[message.createdBy.email] = [];
        }
        groups[message.createdBy.email].push(message);
    });
    return groups;
};

export const Messaging = ({ onNewMessage, messages }: MessagingProps) => {
    const [messageContent, setMessageContent] = useState('');
    const [messageGroups, setMessageGroups] = useState<{
        [key: string]: ReviewMessage[];
    }>({});

    useEffect(() => {
        const groupedMessages = groupMessagesByUser(messages || []);
        setMessageGroups(groupedMessages);
    }, [messages]);

    const handleSendMessage = () => {
        if (messageContent.trim()) {
            onNewMessage({
                message: messageContent,
            });
            setMessageContent('');
        }
    };

    return (
        <div className="mt-2">
            <div className="col-span-full">
                <label
                    htmlFor="about"
                    className="block text-sm font-medium leading-6 text-gray-900"
                >
                    Conversation History
                </label>
                <div>
                    {messages.length === 0 && (
                        <div className="mt-3">
                            <p className={`text-sm text-gray-500 `}>
                                No messages yet, would you like to send one?
                            </p>
                        </div>
                    )}
                    {Object.entries(messageGroups).map(
                        ([username, userMessages], groupIndex) => {
                            const alignmentClass =
                                username === user.value.email
                                    ? 'shift-right'
                                    : 'shift-left';
                            return (
                                <>
                                    <div
                                        key={username + groupIndex}
                                        className={`mt-3 p-2 w-4/6 bg-blue-50 shadow-sm rounded-lg ${alignmentClass === 'shift-left' ? 'mr-auto' : 'ml-auto'}`}
                                    >
                                        {userMessages.map((message, index) => (
                                            <p
                                                key={index}
                                                className={`text-sm text-gray-500 ${alignmentClass}`}
                                            >
                                                {message.message ?? '[blank]'}
                                            </p>
                                        ))}
                                        <p
                                            className={`text-sm text-gray-800 ${alignmentClass}`}
                                        ></p>
                                    </div>
                                    <div
                                        className={`m-2 ${alignmentClass === 'shift-left' ? 'text-left' : 'text-right'}`}
                                    >
                                        <strong>{username}</strong>
                                    </div>
                                </>
                            );
                        },
                    )}
                </div>
            </div>
            <div className="col-span-full mt-2">
                <div className="mt-3 relative flex flex-col items-end justify-end ">
                    <textarea
                        id="about"
                        name="about"
                        rows={2}
                        className="block w-full min-h-14 max-h-32 border-1 p-1.5 text-gray-900 ring-gray-300 border-1 rounded-md shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                        value={messageContent}
                        onChange={(e) => setMessageContent(e.target.value)}
                    />
                    <div className="absolute right-0 bottom-0 mb-2 mr-2 bg-red">
                        <button
                            type="button"
                            className="flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
                            onClick={handleSendMessage}
                        >
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
