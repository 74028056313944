// ResultDetails.tsx
import React, { useEffect, useState } from 'react';

import { ResultDetails, ResultSection } from '../Types/Card';

interface ResultDetailsProps {
    resultDetails: ResultDetails;
    setResultDetails: React.Dispatch<React.SetStateAction<ResultDetails>>;
}

const ResultDetailsComponent: React.FC<ResultDetailsProps> = ({
    resultDetails,
    setResultDetails,
}) => {
    const [inquiryChecked, setInquiryChecked] = useState(false);
    const [exceptionDisclosureChecked, setExceptionDisclosureChecked] = useState(false);
    const [basisOfDesignChecked, setBasisOfDesignChecked] = useState(false);

    useEffect(() => {
        setInquiryChecked(
            Boolean(
                resultDetails.inquiry?.notes ||
                    resultDetails.inquiry?.tests ||
                    resultDetails.inquiry?.results,
            ),
        );
        setExceptionDisclosureChecked(
            Boolean(
                resultDetails.exceptionDisclosure?.notes ||
                    resultDetails.exceptionDisclosure?.tests ||
                    resultDetails.exceptionDisclosure?.results,
            ),
        );
        setBasisOfDesignChecked(
            Boolean(
                resultDetails.basisOfDesign?.notes ||
                    resultDetails.basisOfDesign?.tests ||
                    resultDetails.basisOfDesign?.results,
            ),
        );
    }, [resultDetails]);

    const handleInputChange = (
        section: keyof ResultDetails,
        field: keyof ResultSection,
        value: string,
    ) => {
        setResultDetails((prevDetails) => {
            const updatedSection: ResultSection = {
                ...(prevDetails[section] || {}),
                [field]: value,
            };
            return {
                ...prevDetails,
                [section]: updatedSection,
            };
        });
    };

    return (
        <form>
            <div className="space-y-8">
                <div className="border-b border-gray-900/10 pb-12">
                    <div className="relative flex">
                        <div className="flex h-6 items-center justify-center">
                            <input
                                id="inquiry"
                                name="inquiry"
                                type="checkbox"
                                checked={inquiryChecked}
                                onChange={() => setInquiryChecked(!inquiryChecked)}
                                className="h-4 w-4 mr-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <h3 className="text-base font-semibold leading-7 text-gray-900">
                                <label htmlFor="inquiry">Inquiry</label>
                            </h3>
                        </div>
                    </div>
                    {inquiryChecked && (
                        <>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                Provide information about the inquiry.
                            </p>
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="col-span-full">
                                    <label
                                        htmlFor="inquiryTestResults"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Inquiry Tests
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            id="inquiryTestResults"
                                            name="inquiryTestResults"
                                            rows={3}
                                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            defaultValue={
                                                resultDetails.inquiry?.tests || ''
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'inquiry',
                                                    'tests',
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-span-full">
                                    <label
                                        htmlFor="inquiryNotes"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Inquiry Results
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            id="inquiryNotes"
                                            name="inquiryNotes"
                                            rows={3}
                                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            defaultValue={
                                                resultDetails.inquiry?.results || ''
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'inquiry',
                                                    'results',
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="border-b border-gray-900/10 pb-12">
                    <div className="relative flex">
                        <div className="flex h-6 items-center justify-center">
                            <input
                                id="exceptionDisclosure"
                                name="exceptionDisclosure"
                                type="checkbox"
                                checked={exceptionDisclosureChecked}
                                onChange={() =>
                                    setExceptionDisclosureChecked(
                                        !exceptionDisclosureChecked,
                                    )
                                }
                                className="h-4 w-4 mr-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <h3 className="text-base font-semibold leading-7 text-gray-900">
                                <label htmlFor="exceptionDisclosure">
                                    Exception/Disclosure
                                </label>
                            </h3>
                        </div>
                    </div>
                    {exceptionDisclosureChecked && (
                        <>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                Provide information about the exception or disclosure.
                            </p>
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="col-span-full">
                                    <label
                                        htmlFor="exceptionDisclosureTestResults"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Exception/Disclosure Notes
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            id="exceptionDisclosureTestResults"
                                            name="exceptionDisclosureTestResults"
                                            rows={3}
                                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            defaultValue={
                                                resultDetails.exceptionDisclosure
                                                    ?.notes || ''
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'exceptionDisclosure',
                                                    'notes',
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-span-full">
                                    <label
                                        htmlFor="exceptionDisclosureTestResults"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Exception/Disclosure Tests
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            id="exceptionDisclosureTestResults"
                                            name="exceptionDisclosureTestResults"
                                            rows={3}
                                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            defaultValue={
                                                resultDetails.exceptionDisclosure
                                                    ?.tests || ''
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'exceptionDisclosure',
                                                    'tests',
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-span-full">
                                    <label
                                        htmlFor="exceptionDisclosureNotes"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Exception/Disclosure Results
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            id="exceptionDisclosureNotes"
                                            name="exceptionDisclosureNotes"
                                            rows={3}
                                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            defaultValue={
                                                resultDetails.exceptionDisclosure
                                                    ?.results || ''
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'exceptionDisclosure',
                                                    'results',
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="border-b border-gray-900/10 pb-12">
                    <div className="relative flex">
                        <div className="flex h-6 items-center justify-center">
                            <input
                                id="basisOfDesign"
                                name="basisOfDesign"
                                type="checkbox"
                                checked={basisOfDesignChecked}
                                onChange={() =>
                                    setBasisOfDesignChecked(!basisOfDesignChecked)
                                }
                                className="h-4 w-4 mr-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <h3 className="text-base font-semibold leading-7 text-gray-900">
                                <label htmlFor="basisOfDesign">Basis of Design</label>
                            </h3>
                        </div>
                    </div>
                    {basisOfDesignChecked && (
                        <>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                Provide information about the basis of design test.
                            </p>
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="col-span-full">
                                    <label
                                        htmlFor="basisOfDesignTestResults"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Basis of Design Test Results
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            id="basisOfDesignTestResults"
                                            name="basisOfDesignTestResults"
                                            rows={3}
                                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            defaultValue={
                                                resultDetails.basisOfDesign?.tests || ''
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'basisOfDesign',
                                                    'tests',
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-span-full">
                                    <label
                                        htmlFor="basisOfDesignNotes"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Basis of Design Notes
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            id="basisOfDesignNotes"
                                            name="basisOfDesignNotes"
                                            rows={3}
                                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            defaultValue={
                                                resultDetails.basisOfDesign?.results || ''
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'basisOfDesign',
                                                    'results',
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </form>
    );
};

export default ResultDetailsComponent;
